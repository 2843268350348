html {
    position: relative;
    min-height: 100%;
}
body {
    margin-bottom: 170px;
}
footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 170px;
}